const AboutComponent = () => {
  return (
    <>
      <header className="about-masthead">
        <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
          <div className="row d-flex justify-content-center">
            <div className="col-8">
              <div className="row mt-4">
                <div className="col-lg-5">
                  <div className="text-center">
                    <img
                      className="img-fluid py-2"
                      src="assets/img/about-me.jpg"
                      alt="..."
                    />
                    <div className="text-white mt-2">
                      <i className="fab fa-instagram mx-1"></i>{" "}
                      <i className="fab fa-facebook-square mx-1"></i>{" "}
                      <i className="fab fa-youtube-square mx-1"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <h4 className="text-white px-4">About Me</h4>
                  <p className="mb-0 text-white-50 px-4">
                    Nyi Lin Han started his career in Yangon after getting his
                    bachelor degree from Oxford Brookes University. After coming
                    back to Yangon, he started working in E-commerce industries
                    and advertising agencies. Then, he moved into film industry
                    learning and working as a producer for 4 years from the
                    start of Green Box later rebrand into Green Screen
                    Productions. Green Screen established itself as one of
                    Myanmar’s best video production companies, delivering
                    professional, compelling and memorable films for clients in
                    all sorts of businesses and budgets. Today Green Screen
                    Productions works for a number of agencies, charities and
                    individual companies delivering a huge variety of contents.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default AboutComponent;
